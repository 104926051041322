<script setup lang="ts">
import { DotLoader } from '@wision/ui';
import DashboardGrid from '../../features/dashboard/DashboardGrid.vue';
import { onMounted } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import 'vue3-grid-layout-next/dist/style.css';
import '@/features/dashboard/styles.css';
import { withUseQuery } from '@wision/api';

definePageMeta({
  title: 'Dashboard',
});

const apiClient = useApi();
const route = useRoute();
const session = useSession();

const dashboardId = route.params.dashboard as string;

const [dashboard, dashboardError] = await apiClient.dashboard.get(
  dashboardId ?? ''
);
const [widgets, widgetError] = await apiClient.dashboard.getWidgets();

if (
  session.get.value.currentOrganization &&
  (!dashboard.organizationId ||
    session.get.value.currentOrganization != +dashboard.organizationId)
) {
  const [dashboardList] = await apiClient.dashboard.getall(true, true);

  if (dashboardList && dashboardList.length > 0) {
    navigateTo(`/dashboard/${dashboardList[0].id}`);
  } else navigateTo('/dashboard');
}

onMounted(() => {
  const queryClient = useQueryClient();
  queryClient.ensureQueryData({
    queryKey: ['deviceFilter', dashboard.filter],
    queryFn: () =>
      withUseQuery(() =>
        apiClient.device.getDeviceFilter(true, dashboard.filter)
      ),
  });
});

</script>

<template>
  <div>
    <NuxtLayout
      name="layout"
      :title="`${$t('pages.maintitle')} | ${
        dashboard.content.name.includes('nav')
          ? $t(dashboard.content.name)
          : dashboard.content.name
      }`"
      :error="dashboardError || widgetError"
      :header-title="
        dashboard.content.name.includes('nav')
          ? $t(dashboard.content.name)
          : dashboard.content.name
      "
    >
      <ClientOnly fallback-tag="div">
        <DashboardGrid
          v-if="!(dashboardError || widgetError)"
          :dashboard-id="dashboardId ?? ''"
          :available-widgets="widgets"
          :dashboard="dashboard"
        />
        <template #fallback>
          <div class="w-full h-full flex items-center justify-center">
            <DotLoader color="white" />
          </div>
        </template>
      </ClientOnly>
    </NuxtLayout>
  </div>
</template>
