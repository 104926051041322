<script setup lang="ts">
import type { Widget, Dashboard } from '@wision/api';
import type { PropType } from 'vue';
import { toRaw } from 'vue';
import { PlusIcon, Dropdown } from '@wision/ui';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import { $dashboard } from './store';
import { toast } from 'vue3-toastify';

const { t } = useI18n();
const DEFAULT_LINK_GROUP_ID = 'default';
const GRID_COL_BREAKPOINTS = { md: 12, sm: 6, xs: 2 };

const props = defineProps({
  widgets: {
    type: Array as PropType<Widget[]>,
    required: true,
  }
});

const findSlot = (grid: boolean[][], sizeX: number, sizeY: number) => {
  const numRows = grid.length;
  const numCols = grid[0].length;

  for (let row = 0; row <= numRows - sizeY; row++) {
    for (let col = 0; col <= numCols - sizeX; col++) {
      let isSlotAvailable = true;
      for (let i = row; i < row + sizeY; i++) {
        for (let j = col; j < col + sizeX; j++) {
          if (grid[i][j] === true) {
            isSlotAvailable = false;
            break;
          }
        }
        if (!isSlotAvailable) break;
      }

      if (isSlotAvailable) {
        return { row, col };
      }
    }
  }

  return null;
};

const handleAddWidget = (widget: Widget) => {
  const dashboard = JSON.parse(JSON.stringify(toRaw($dashboard.get()))) as Dashboard;
  if (widget.component === 'MapWidget') {
    const existingMapWidget = Object.values(dashboard.widgets).find(widget => widget.component === 'MapWidget');
    if (existingMapWidget) {
      toast(t('map.onemaperror'), {
        autoClose: 2000,
        type: 'error',
        theme: 'dark',
      });
      return;
    }
  }
  const breakpoint = 'sm';
  const widgets = dashboard.widgets;

  let id = widget.layouts[breakpoint].i + nanoid(10);
  while (widgets[id]) {
    id = widget.layouts[breakpoint].i + nanoid(10);
  }

  widgets[id] = {
    component: widget.component,
    group: DEFAULT_LINK_GROUP_ID,
    name: widget.name,
  };

  let breakpointName: keyof typeof GRID_COL_BREAKPOINTS;
  for (breakpointName in GRID_COL_BREAKPOINTS) {
    const layout = dashboard.layouts[breakpointName];
    const currentWidgetBreakpoint = widget.layouts[breakpointName];
    const widgetHeight = currentWidgetBreakpoint.h;
    const widgetWidth = currentWidgetBreakpoint.w;

    let height = 0;
    let grid: boolean[][] = [[]];

    if (layout.length > 0) {
      height = Math.max(...layout.map((x) => x.y + x.h));
      const width = GRID_COL_BREAKPOINTS[breakpointName];
      grid = new Array(height)
        .fill(null)
        .map(() => new Array(width).fill(false));

      layout.forEach((widget) => {
        for (let j = 0; j < widget.h; j++) {
          for (let i = 0; i < widget.w; i++) {
            grid[j + widget.y][i + widget.x] = true;
          }
        }
      });
    }

    const slot = findSlot(grid, widgetWidth, widgetHeight);
    const widgetLayout = {
      ...widget.layouts[breakpointName],
      i: id,
      x: slot ? slot.col : 0,
      y: slot ? slot.row : height,
    };
    layout.push(widgetLayout);
  }

  $dashboard.set(dashboard);
};
</script>

<template>
  <div class="my-4 relative">
    <Dropdown>
      <template #button>
        <div class="flex items-center gap-2 cursor-pointer">
          <PlusIcon />
          <p>{{ t('common.addwidget') }}</p>
        </div>
      </template>
      <template #default="dropdownContent">
        <div class="max-h-96 overflow-auto scroll-bar-thin p-2">
          <div
            v-for="widget in props.widgets"
            :key="widget.name"
            class="flex flex-col text-base cursor-pointer hover:bg-gray-600"
            @click="
              () => {
                handleAddWidget(widget);
                dropdownContent.close();
              }
            "
          >
            <div class="flex flex-row mb-0 px-2.5 py-1.5 items-center">
              <img
                class="w-12 h-12"
                :alt="widget.name"
                :src="`/assets/${widget.image}.svg`"
              >
              <div class="ml-2.5 text-white font-bold">
                <p class="text-sm">
                  {{ t(widget.name) }}
                </p>
                <p class="text-xs font-light">
                  {{ t(widget.description) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>
