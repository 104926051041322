<script setup lang="ts">
import { getContentMaxHeight } from '../../utils';
import {
  FilterModal,
  DotLoader,
} from '@wision/ui';
import { onMounted, ref, watch, onUnmounted } from 'vue';
import type { PropType } from 'vue';
import {
  withUseQuery,
} from '@wision/api';
import type {  ApiReturnType, SelectedFilters, Dashboard, DeviceFilter } from '@wision/api';
import { useI18n } from 'vue-i18n';
import AddWidget from './AddWidget.vue';
import { useQuery } from '@tanstack/vue-query';
import DynamicWidget from '../widget/DynamicWidget.vue';
import { GridLayout, GridItem } from 'vue3-grid-layout-next';
import { $dashboard, $devices, $breakPoint, $isLoadingDevices, updateBreakPoint } from './store';
import { useStore } from '@nanostores/vue';
import WidgetProvider from '@/features/widget/Provider.vue';
import { listenKeys } from 'nanostores';
import { useDebounceFn } from '@vueuse/core';

const GRID_COL_NUM = 12;
const GRID_ROW_HEIGHT = 64;
const GRID_BREAKPOINTS = { md: 900, sm: 671, xs: 480 };
const GRID_COL_BREAKPOINTS = { md: 12, sm: 6, xs: 2 };
const DRAG_HANDLE_CLASS = 'drag-handle';

const { t } = useI18n();
const apiClient = useApi();

const props = defineProps({
  dashboardId: {
    type: String,
    required: true,
  },
  dashboard: {
    type: Object as PropType<ApiReturnType<typeof apiClient.dashboard.get>>,
    required: true,
  },
  availableWidgets: {
    type: Object as PropType<ApiReturnType<typeof apiClient.dashboard.getWidgets>>,
    required: true,
  },
});

type Layout = typeof props.dashboard.content.layouts.md;

$dashboard.set(JSON.parse(JSON.stringify(props.dashboard.content)));

const layouts = ref(JSON.parse(JSON.stringify(props.dashboard.content.layouts)) as typeof props.dashboard.content.layouts);
const widgets = ref(JSON.parse(JSON.stringify(props.dashboard.content.widgets)) as typeof props.dashboard.content.widgets);
const mounted = ref(false);
const contentMaxHeight = ref(0);
const isMobile = false;
const breakPoint = useStore($breakPoint);
const filters = ref<DeviceFilter | null>();
const selectedFilters = ref(props.dashboard.filter);

const {
  data: deviceFilter,
  isLoading: isLoadingDeviceFilters
} = useQuery({
  queryKey: ['deviceFilter', selectedFilters],
  queryFn: () => withUseQuery(() => apiClient.device.getDeviceFilter(true, selectedFilters.value)),
  refetchInterval: 1000 * 60 * 5
});

const updateDashboard = useDebounceFn((dashboard: Dashboard) => {
  if (!mounted.value)
    return;

  apiClient.dashboard.set(props.dashboard.id, dashboard);
}, 500);

onMounted(async () => {
  if (!filters.value && deviceFilter.value?.filter) {
    filters.value = deviceFilter.value.filter;
  }

  if (deviceFilter.value?.devices)
      $devices.set(deviceFilter.value?.devices ?? []);

  window.addEventListener('resize', () => {
    contentMaxHeight.value = getContentMaxHeight();
  });
  mounted.value = true;
  contentMaxHeight.value = getContentMaxHeight();

  $dashboard.subscribe((dashboard) => {
    updateDashboard(dashboard);
  });
});

onUnmounted(() => {
  mounted.value = false;
});

const handleUpdateFilter = async (filter: SelectedFilters) => {
  selectedFilters.value = filter;
  await apiClient.dashboard.updateFilter(filter, props.dashboard.id);
};

const handleLayoutUpdate = (layout: Layout) => {
  $dashboard.setKey(`layouts.${breakPoint.value}`, [...layout]);
};

listenKeys($dashboard, ['layouts'], (dashboard) => {
  widgets.value = JSON.parse(JSON.stringify(dashboard.widgets));
  layouts.value = JSON.parse(JSON.stringify(dashboard.layouts));
});

watch(deviceFilter, () => {
  $devices.set(deviceFilter.value?.devices ?? []);
  if (deviceFilter.value?.filter)
    filters.value = deviceFilter.value?.filter;
});

watch(isLoadingDeviceFilters, () => $isLoadingDevices.set(isLoadingDeviceFilters.value));
</script>

<template>
  <div
    v-if="isLoadingDeviceFilters"
    class="fixed w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div class="opacity-100">
      <DotLoader color="white" />
    </div>
  </div>
  <div class="bg-dotted dark:bg-black">
    <div
      ref="dashboardGrid"
      class="h-full z-5 relative"
      :class="{ 'px-2.5': !isMobile }"
    >
      <div class="px-1 text-white" :style="{ height: contentMaxHeight + 'px' }">
        <div class="flex items-center justify-between">
          <div class="flex flex-row gap-2">
            <div class="m-auto border-r border-current pr-2 hidden sm:block">
              <span class="ml-2 capitalize">
                {{ t('kpi.units') }} / {{ deviceFilter?.devices.length }}
              </span
              >
            </div>
            <AddWidget :widgets="props.availableWidgets" />
          </div>
          <FilterModal
            v-if="filters"
            :filter="filters"
            :on-update-filter="handleUpdateFilter"
            :selected-filters="selectedFilters"
          />
        </div>
        <div
          class="overflow-y-auto scroll-bar-thin absolute top-12 bottom-0 left-2 right-2"
        >
          <GridLayout
            :layout="layouts[breakPoint]"
            :responsive-layouts="layouts"
            :col-num="GRID_COL_NUM"
            :cols="(GRID_COL_BREAKPOINTS as any)"
            :row-height="GRID_ROW_HEIGHT"
            :responsive="true"
            :is-draggable="true"
            :is-resizable="true"
            :vertical-compact="true"
            :use-css-transforms="true"
            :breakpoints="(GRID_BREAKPOINTS as any)"
            @breakpoint-changed="updateBreakPoint"
            @layout-updated="(layout) => handleLayoutUpdate(layout as Layout)"
          >
            <GridItem
              v-for="pos in layouts[breakPoint]"
              :key="pos.i"
              :x="pos.x"
              :y="pos.y"
              :w="pos.w"
              :h="pos.h"
              :i="pos.i"
              :min-h="pos.mH"
              :min-w="pos.mW"
              :drag-allow-from="`.${DRAG_HANDLE_CLASS}`"
              :horizontal-shift="true"
            >
              <WidgetProvider
                :widget-id="pos.i"
                :title="t(widgets[pos.i]?.name ?? '')"
                :drag-handle-class="DRAG_HANDLE_CLASS"
              >
                <DynamicWidget
                  :widget-component-id="widgets[pos.i].component"
                />
              </WidgetProvider>
            </GridItem>
          </GridLayout>
        </div>
      </div>
    </div>
  </div>
</template>
