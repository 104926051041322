<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { deleteWidget } from '@/features/dashboard/store';
import { useWidget } from './Provider.vue';

const { widgetId } = useWidget();

const widgetComponents = {
  ListWidget: defineAsyncComponent(() => import('./widgets/ListWidget.vue')),
  MapWidget: defineAsyncComponent(() => import('./widgets/MapWidget.vue')),
  UnitAlarmWidget: defineAsyncComponent(() => import('./widgets/AlarmListWidget.vue')),
  GroupAlarmWidget: defineAsyncComponent(() => import('./widgets/GroupAlarmWidget.vue')),
  AlarmHistoryWidget: defineAsyncComponent(() => import('./widgets/AlarmHistoryWidget.vue')),
  ChartWidget: defineAsyncComponent(() => import('./widgets/XChartWidget.vue')),
  MultiChartWidget: defineAsyncComponent(() => import('./widgets/MultiChartWidget.vue')),
  AlarmMonitorWidget: defineAsyncComponent(() => import('./widgets/AlarmMonitorWidget.vue')),
  ConsumptionWidget: defineAsyncComponent(() => import('./widgets/ConsumptionWidget.vue')),
  IndoorClimateWidget: defineAsyncComponent(() => import('./widgets/IndoorClimateWidget.vue')),
  StatusWidget: defineAsyncComponent(() => import('./widgets/StatusWidget.vue')),
  AirQualityWidget: defineAsyncComponent(() => import('./widgets/AirQualityWidget.vue')),
  RelayWidget: defineAsyncComponent(() => import('./widgets/RelayWidget.vue')),
  WeatherWidget: defineAsyncComponent(() => import('./widgets/WeatherWidget.vue')),
  TempWidget: defineAsyncComponent(() => import('./widgets/TempWidget.vue')),
  CameraWidget: defineAsyncComponent(() => import('./widgets/CameraWidget.vue'))
};

const props = defineProps({
  widgetComponentId: {
    type: String,
    required: true
  }
});
</script>

<template>
  <component
    :is="widgetComponents[props.widgetComponentId as keyof typeof widgetComponents]"
    v-if="widgetComponents.hasOwnProperty(props.widgetComponentId)"
  />
  <div
    v-else
    class="bg-gray-800 w-full h-full rounded-md flex flex-col p-10 items-center justify-center"
  >
    Could not find the widget you are looking for.
    <button
      class="border rounded-md p-2 mt-2"
      @click="() => deleteWidget(widgetId)"
    >
      Delete
    </button>
  </div>
</template>
