import { atom, deepMap } from 'nanostores';
import { type DeviceBase, type Dashboard } from '@wision/api';

type BreakPoint = 'xs' | 'sm' | 'md';

export const $isLoadingDevices = atom<boolean>(true);
export const $devices = atom<DeviceBase[]>([]);
export const $dashboard = deepMap<Dashboard>();
export const $breakPoint = atom<BreakPoint>('md');
export const $lastMessageSender = atom<string | undefined>();

export const deleteLinkGroup = (linkGroupId: string) => {
  const dashboard = JSON.parse(JSON.stringify($dashboard.get())) as Dashboard;
  Object.values(dashboard.widgets).forEach(widget => {
    if (widget.group === linkGroupId)
      widget.group = 'default';
  });

  delete dashboard.linkGroups[linkGroupId];

  $dashboard.set(dashboard);
};

export const updateBreakPoint = (newBreakPoint: string) => {
  $breakPoint.set(newBreakPoint as BreakPoint);
};

export const deleteWidget = (widgetId: string) => {
  const dashboard = $dashboard.get();
  const layouts = { ...dashboard.layouts };
  const widgets = { ...dashboard.widgets };
  const widget = widgets[widgetId];

  if (dashboard.linkGroupMessages && widget) {
    const groupId = widget.group as string;
    const filteredMessages = dashboard
      .linkGroupMessages[groupId]
      ?.filter(message => message.sender !== widgetId);

    $dashboard.setKey(`linkGroupMessages.${groupId}`, filteredMessages);
  }

  let layoutKey: keyof typeof layouts;
  for (layoutKey in layouts) {
    layouts[layoutKey] = layouts[layoutKey].filter(
      (widget) => widget.i != widgetId
    );
  }

  delete widgets[widgetId];

  $dashboard.setKey('layouts', layouts);
  $dashboard.setKey('widgets', widgets);
};

export const updateWidgetSettings = (widgetId: string, settings: Record<string, unknown>) => {
  $dashboard.setKey(`widgets.${widgetId}.props`, settings);
};
