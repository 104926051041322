<template>
  <svg 
    width="22px" 
    height="22px" 
    viewBox="0 0 24 24" 
    xmlns="http://www.w3.org/2000/svg" 
    class="fill-current"
  >
    <g 
      stroke="none" 
      stroke-width="1" 
      fill="none" 
      fill-rule="evenodd"
    >
      <g transform="translate(-508.000000, -169.000000)">
        <g transform="translate(87.000000, 158.000000)">
          <g transform="translate(421.000000, 11.000000)">
            <polygon 
              points="0 0 24 0 24 24 0 24"
            />
            <path 
              d="M10,18 L14,18 L14,16 L10,16 L10,18 Z M3,6 L3,8 L21,8 L21,6 L3,6 Z M6,13 L18,13 L18,11 L6,11 L6,13 Z" 
              class="fill-current" 
              fill-rule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
